import React from 'react';
import Layout from '../layouts/index';

const NotFoundContent = () => (
  <div className="container" style={{ padding: '100px 0', minHeight: 800 }}>
    <h1>お探しのページは見つかりませんでした</h1>
    <p>
      申し訳ありませんが、お探しのページは見つかりませんでした。
      <br />
      ページが削除されたか、移動された可能性があります。
    </p>
  </div>
);

const NotFound = () => (
  <Layout>
    <NotFoundContent />
  </Layout>
);

export default NotFound;
